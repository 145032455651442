import { render, staticRenderFns } from "./AddressDetails.vue?vue&type=template&id=fd42e096&scoped=true&"
import script from "./AddressDetails.vue?vue&type=script&lang=js&"
export * from "./AddressDetails.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fd42e096",
  null
  
)

export default component.exports