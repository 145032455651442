<template>
    <div class="order-table-container px-0 mx-0">
        <div class="d-flex justify-content-end align-items-center mb-2">
            <b-form-checkbox v-model="showImplanted" switch>
                {{ t('invLookup_showConsumed') }}
            </b-form-checkbox>
        </div>
        <b-table
            responsive
            id="order-table"
            class="table-list"
            :items="filteredInventory"
            :fields="fields"
            :sort-by="sortKey"
            :sort-desc="sortDesc"
            sort-icon-left
        >
            <!-- Filterable columns -->
            <template v-for="column in filterableColumns" v-slot:[`head(${column.key})`]>
                <div
                    :key="column.key"
                    class="filtered-header"
                    :class="isCenterText(column.key) ? 'center-text' : ''"
                    :style="getColMinWidth(column.key)"
                >
                    <b-button :id="'dropdown-' + column.key" tabindex="0">
                        {{ column.label }} <b-icon icon="filter"></b-icon>
                    </b-button>
                    <b-popover
                        :target="'dropdown-' + column.key"
                        triggers="focus"
                        placement="bottom"
                        tabindex="0"
                        boundary="body"
                    >
                        <div class="scrollable-dropdown-menu">
                            <div
                                v-for="value in uniqueValues(column.key)"
                                :key="value"
                                class="dropdown-item"
                                @click="applyFilters(column.key, value)"
                            >
                                <b-form-checkbox
                                    :value="value"
                                    v-model="filters[column.key]"
                                    @change="applyFilters(column.key, value)"
                                >
                                    {{ value }}
                                </b-form-checkbox>
                            </div>
                        </div>
                    </b-popover>
                    <b-icon
                        v-if="filters[column.key].length > 0"
                        icon="x-circle"
                        class="ml-2 clear-filters-icon"
                        title="Clear Filters"
                        @click.stop="clearColumnFilters(column.key)"
                    ></b-icon>
                </div>
            </template>

            <!-- Center text in specific columns -->
            <template #cell(cylinder)="data">
                <div class="center-text">
                    {{ data.value }}
                </div>
            </template>

            <template #cell(targetAxis)="data">
                <div class="center-text">
                    {{ data.value }}
                </div>
            </template>

            <template #head()="data">
                <div :style="getColMinWidth(data.column)">
                    {{ data.label }}
                </div>
            </template>
            <template #cell(status)="{value, item}">
                <div class="d-flex">
                    <b-badge pill :variant="getStatusBadgeColor(item.statusId)">
                        {{ value }}
                    </b-badge>
                </div>
            </template>
        </b-table>
    </div>
</template>

<script>
import get from 'lodash/get';
import {DEFAULT_DATE_FORMAT} from '@/config';
import {mapState, mapGetters} from 'vuex';
import {formatLensNum, formatWithPadding} from '@/utilities/formatters';
import {ConsignmentLensStatuses} from '@/constants/inventory';

export default {
    props: {
        queryParams: {
            type: Object,
            default: () => {},
        },
        activeInventoryType: {
            type: String,
            default: 'spheric',
        },
    },
    data() {
        return {
            colMinWidths: {
                expiration: '13rem',
            },
            filterableColumns: [
                {key: 'model', label: this.t('invLookupCol_Model')},
                {key: 'sphere', label: this.t('invLookupCol_Sphere')},
                {key: 'cylinder', label: this.t('invLookupCol_Cylinder')},
                {key: 'targetAxis', label: this.t('invLookupCol_Axis')},
                {key: 'status', label: this.t('statusNormalCase')},
            ],
            filters: {
                model: [],
                sphere: [],
                cylinder: [],
                targetAxis: [],
                status: [],
            },
            showImplanted: false,
            sortKey: 'model',
            sortDesc: false,
        };
    },
    computed: {
        ...mapGetters('user', ['currentUser']),
        ...mapState({
            inventory: (state) => state.inventory.inventoryList,
        }),
        activeInventory() {
            return get(this.inventory, this.activeInventoryType, []);
        },
        filteredInventory() {
            let inventory = this.activeInventory;

            // Apply non-status filters first
            for (const key in this.filters) {
                if (key !== 'status' && this.filters[key].length > 0) {
                    inventory = inventory.filter((item) => this.filters[key].includes(item[key]));
                }
            }

            if (this.showImplanted) {
                // If showImplanted is true, include all implanted (consumed) items and filter the rest based on status filters
                const implantedItems = this.activeInventory.filter(
                    (item) => item.status === 'Implanted'
                );

                const nonImplantedItems = this.applyStatusFilter(inventory);

                return [...implantedItems, ...nonImplantedItems].filter((item) =>
                    inventory.includes(item)
                );
            } else {
                // Simply apply status filter if showImplanted is false
                return this.applyStatusFilter(inventory);
            }
        },
        fields() {
            const tableFields = [
                {
                    key: 'inventoryItemId',
                    stickyColumn: true,
                    label: this.t('serialNo'),
                    sortable: true,
                    formatter: (_, __, row) => {
                        return get(row, 'serials.0.serialNumber');
                    },
                    sortByFormatted: true,
                    filterByFormatted: true,
                },
                {
                    key: 'model',
                    label: this.t('invLookupCol_Model'),
                    sortable: true,
                },
                {
                    key: 'sphere',
                    label: this.t('invLookupCol_Sphere'),
                    formatter: (value) =>
                        `${this.formatLensNum(value, 2, this.currentUser.decimalSeparator)} D`,
                    sortable: true,
                    sortByFormatted: true,
                    filterByFormatted: true,
                },
            ];

            if (this.activeInventoryType === 'toric') {
                tableFields.push({
                    key: 'cylinder',
                    label: this.t('invLookupCol_Cylinder'),
                    formatter: (value) =>
                        `${this.formatWithPadding(
                            value,
                            this.currentUser.decimalSeparator,
                            2,
                            2,
                            true
                        )} D`,
                    sortable: true,
                    sortByFormatted: true,
                    filterByFormatted: true,
                });
                tableFields.push({
                    key: 'targetAxis',
                    label: this.t('invLookupCol_Axis'),
                    formatter: (value) => this.formatWithPadding(value, null, 0, 3, false),
                    sortable: true,
                    sortByFormatted: true,
                    filterByFormatted: true,
                });
            }

            tableFields.push({
                key: 'serials',
                label: this.t('expiration'),
                formatter: (value) =>
                    this.$options.filters.date(get(value, '0.expirationDate'), {
                        format: DEFAULT_DATE_FORMAT,
                        isUTC: false,
                    }),
                sortable: true,
                sortByFormatted: true,
                filterByFormatted: true,
            });
            tableFields.push({key: 'status', label: this.t('statusNormalCase'), sortable: true});

            return tableFields;
        },
    },
    methods: {
        formatLensNum,
        formatWithPadding,
        getStatusBadgeColor(statusId) {
            switch (statusId) {
                case ConsignmentLensStatuses.AVAILABLE:
                    return 'success';
                case ConsignmentLensStatuses.RESERVED:
                    return 'warning';
                case ConsignmentLensStatuses.CONSUMED:
                    return 'primary';
                default:
                    return 'dark';
            }
        },
        uniqueValues(key) {
            // Returns the unique values in a given column, sorted.
            const values = [...new Set(this.activeInventory.map((item) => item[key]))];

            const numericValues = values
                .filter((value) => typeof value === 'number')
                .sort((a, b) => a - b);
            const stringValues = values
                .filter((value) => typeof value === 'string' && value !== 'Implanted')
                .sort();

            return [...numericValues, ...stringValues];
        },
        clearColumnFilters(columnKey) {
            this.filters[columnKey] = [];
        },
        applyFilters(key, value) {
            if (!this.filters[key].includes(value)) {
                this.filters[key].push(value);
            } else {
                this.filters[key] = this.filters[key].filter((item) => item !== value);
            }
        },
        applyStatusFilter(inventory) {
            if (this.filters.status.length > 0) {
                return inventory.filter((item) => this.filters.status.includes(item.status));
            }
            return inventory.filter((item) => item.status !== 'Implanted');
        },
        isCenterText(key) {
            return ['cylinder', 'targetAxis'].includes(key);
        },
        getColMinWidth(column) {
            return {'min-width': this.colMinWidths[column] || '8rem'};
        },
    },
    watch: {
        activeInventoryType(newVal, oldVal) {
            // Clear active filters when tab changes
            this.filters = {
                model: [],
                sphere: [],
                cylinder: [],
                targetAxis: [],
                status: [],
            };

            this.showImplanted = false;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/_variables.scss';

::v-deep .light-border-right {
    border-right: 1px solid $gray-light;
}

.scrollable-dropdown-menu {
    max-height: 500px;
    overflow-y: auto;
    padding: 0;
}

.scrollable-dropdown-menu .dropdown-item {
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
}

.scrollable-dropdown-menu .dropdown-item:hover {
    background-color: $gray-light;
}

@media screen and (max-height: 900px) {
    .scrollable-dropdown-menu {
        max-height: 300px;
    }
}

@media screen and (max-height: 700px) {
    .scrollable-dropdown-menu {
        max-height: 200px;
    }
}

.clear-filters-icon {
    cursor: pointer;
}

.center-text {
    text-align: center;
}

.b-popover-content {
    padding: 0.5rem !important;
}

.filtered-header {
    padding-bottom: 5px !important;
}

.table-list thead tr th > div {
    padding: 0px 1rem 12px 25px;
}
</style>
