import { render, staticRenderFns } from "./SurgeonCustomerSelectorCustomersList.vue?vue&type=template&id=d332ac60&"
import script from "./SurgeonCustomerSelectorCustomersList.vue?vue&type=script&lang=js&"
export * from "./SurgeonCustomerSelectorCustomersList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports